import { defineStore } from "pinia"
import { ref } from "vue"

export const useAssetStore = defineStore("asset", () => {
	const assetList = ref([])
	const assetListLoading = ref(false)

	async function populateAssetList(project) {
		if (this.assetListLoading || !project?.slug) return

		this.assetListLoading = true
		// const project = page.props.project
		let filterString = ""
		const curRoute = route("dashboard.project.assets.index", {
			project: project.slug,
			// filter: "image",
		})
		const response = await http.get(curRoute)
		assetList.value = response.data.map((item) => ({
			id: item.id,
			text: item["name"],
			value: item["id"],
			asset_url: item["asset_url"],
		}))
		this.assetListLoading = false
	}

	function setAssetList(value) {
		this.assetList = value
	}

	function addAsset(value) {
		this.assetList.push({
			id: value.id,
			text: value.name,
			value: value.id,
			asset_url: value.asset_url,
		})
	}

	return {
		assetList,
		assetListLoading,
		setAssetList,
		addAsset,
		populateAssetList,
	}
})
